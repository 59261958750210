import React from 'react'

const Footer = () => {
  return (
    <footer style={{fontSize: '1rem' , height : '5%' , textAlign : 'right' , paddingRight : '50px' , fontWeight : 'bold'}}>
        All rights reserved. &copy; Kamil Stecyk
    </footer>
  )
}

export default Footer